/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */

import $ from 'jquery'
import svg4everybody from 'svg4everybody'
;(function () {
	'use strict'

	// Add "enhanced" class for PE in JS and CSS
	if (
		'classList' in document.createElement('div') &&
		'querySelector' in document &&
		'localStorage' in window &&
		'addEventListener' in window
	) {
		window.document.documentElement.className += ' enhanced'
	}

	//check if ie
	const ua = window.navigator.userAgent
	const isIE = /MSIE|Trident/.test(ua)

	if (isIE) {
		window.document.documentElement.className += ' ie'
	}

	// bwegtPlus accordion always open on first index
	$('.accordion-bwegtPlus #section0').addClass('opened')

	//toggle tooltip
	Array.prototype.forEach.call(document.querySelectorAll('[data-action="toggle-tooltip-rectangle"]'), function (tooltip) {
		// Toggle the message

		if (tooltip) {
			tooltip.addEventListener('click', function (e) {
				e.preventDefault()
				window.setTimeout(function () {
					const tooltipBox = tooltip.querySelector('[data-role="tooltip-rectangle"]')
					if (tooltipBox) {
						if (tooltipBox.classList.contains('_show')) {
							tooltipBox.classList.remove('_show')
							tooltip.classList.remove('_open')
						} else {
							tooltipBox.classList.add('_show')
							tooltip.classList.add('_open')
						}
					}
				}, 100)
			})

			// Remove tooltip on ESC
			tooltip.addEventListener('keydown', function (e) {
				const tooltipBox = tooltip.querySelector('[data-role="tooltip-rectangle"]')
				if ((e.keyCode || e.which) === 27) {
					if (tooltipBox) {
						tooltipBox.classList.remove('_show')
						tooltip.classList.remove('_open')
					}
				}

				if ((e.keyCode || e.which) === 32) e.preventDefault()
				if (tooltipBox) {
					const tooltipBox = tooltip.querySelector('[data-role="tooltip-rectangle"]')
					if (tooltipBox.classList.contains('_show')) {
						tooltipBox.classList.remove('_show')
						tooltip.classList.remove('_open')
					} else {
						tooltipBox.classList.add('_show')
						tooltip.classList.add('_open')
					}
				}
			})

			// Remove on blur
			tooltip.addEventListener('blur', function (e) {
				const tooltipBox = tooltip.querySelector('[data-role="tooltip-rectangle"]')
				if (tooltipBox) {
					tooltip.classList.remove('_open')
					tooltipBox.classList.remove('_show')
				}
			})
		}
	})

	// Init polyfill / shim for SVG sprites
	window.addEventListener('DOMContentLoaded', function (e) {
		svg4everybody()
	})

	// Polyfill for remove() for IE9+
	// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
	;(function (arr) {
		arr.forEach(function (item) {
			if (item.hasOwnProperty('remove')) {
				return
			}
			Object.defineProperty(item, 'remove', {
				configurable: true,
				enumerable: true,
				writable: true,
				value: function remove() {
					if (this.parentNode === null) {
						return
					}
					this.parentNode.removeChild(this)
				},
			})
		})
	})([Element.prototype, CharacterData.prototype, DocumentType.prototype])
})()

//this function will work cross-browser for loading scripts asynchronously
function loadScript(src, callback) {
	var s, r, t
	r = false
	s = document.createElement('script')
	s.type = 'text/javascript'
	s.src = src
	s.onload = s.onreadystatechange = function () {
		//console.log( this.readyState ); //uncomment this line to see which ready states are called.
		if (!r && (!this.readyState || this.readyState == 'complete')) {
			r = true
			callback()
		}
	}
	t = document.getElementsByTagName('script')[0]
	t.parentNode.insertBefore(s, t)
}

window.loadScript = loadScript

/**
 * Polyfill for window.fetch
 */
!(function (t) {
	'use strict'
	if (!t.fetch) {
		var e = 'URLSearchParams' in t,
			r = 'Symbol' in t && 'iterator' in Symbol,
			s =
				'FileReader' in t &&
				'Blob' in t &&
				(function () {
					try {
						return new Blob(), !0
					} catch (t) {
						return !1
					}
				})(),
			o = 'FormData' in t,
			n = 'ArrayBuffer' in t
		if (n)
			var i = [
					'[object Int8Array]',
					'[object Uint8Array]',
					'[object Uint8ClampedArray]',
					'[object Int16Array]',
					'[object Uint16Array]',
					'[object Int32Array]',
					'[object Uint32Array]',
					'[object Float32Array]',
					'[object Float64Array]',
				],
				a = function (t) {
					return t && DataView.prototype.isPrototypeOf(t)
				},
				h =
					ArrayBuffer.isView ||
					function (t) {
						return t && -1 < i.indexOf(Object.prototype.toString.call(t))
					}
		;(l.prototype.append = function (t, e) {
			;(t = d(t)), (e = y(e))
			var r = this.map[t]
			this.map[t] = r ? r + ',' + e : e
		}),
			(l.prototype.delete = function (t) {
				delete this.map[d(t)]
			}),
			(l.prototype.get = function (t) {
				return (t = d(t)), this.has(t) ? this.map[t] : null
			}),
			(l.prototype.has = function (t) {
				return this.map.hasOwnProperty(d(t))
			}),
			(l.prototype.set = function (t, e) {
				this.map[d(t)] = y(e)
			}),
			(l.prototype.forEach = function (t, e) {
				for (var r in this.map) this.map.hasOwnProperty(r) && t.call(e, this.map[r], r, this)
			}),
			(l.prototype.keys = function () {
				var r = []
				return (
					this.forEach(function (t, e) {
						r.push(e)
					}),
					p(r)
				)
			}),
			(l.prototype.values = function () {
				var e = []
				return (
					this.forEach(function (t) {
						e.push(t)
					}),
					p(e)
				)
			}),
			(l.prototype.entries = function () {
				var r = []
				return (
					this.forEach(function (t, e) {
						r.push([e, t])
					}),
					p(r)
				)
			}),
			r && (l.prototype[Symbol.iterator] = l.prototype.entries)
		var u = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT']
		;(_.prototype.clone = function () {
			return new _(this, { body: this._bodyInit })
		}),
			v.call(_.prototype),
			v.call(B.prototype),
			(B.prototype.clone = function () {
				return new B(this._bodyInit, {
					status: this.status,
					statusText: this.statusText,
					headers: new l(this.headers),
					url: this.url,
				})
			}),
			(B.error = function () {
				var t = new B(null, { status: 0, statusText: '' })
				return (t.type = 'error'), t
			})
		var f = [301, 302, 303, 307, 308]
		;(B.redirect = function (t, e) {
			if (-1 === f.indexOf(e)) throw new RangeError('Invalid status code')
			return new B(null, { status: e, headers: { location: t } })
		}),
			(t.Headers = l),
			(t.Request = _),
			(t.Response = B),
			(t.fetch = function (r, n) {
				return new Promise(function (o, t) {
					var e = new _(r, n),
						i = new XMLHttpRequest()
					;(i.onload = function () {
						var t,
							n,
							e = {
								status: i.status,
								statusText: i.statusText,
								headers:
									((t = i.getAllResponseHeaders() || ''),
									(n = new l()),
									t
										.replace(/\r?\n[\t ]+/g, ' ')
										.split(/\r?\n/)
										.forEach(function (t) {
											var e = t.split(':'),
												r = e.shift().trim()
											if (r) {
												var o = e.join(':').trim()
												n.append(r, o)
											}
										}),
									n),
							}
						e.url = 'responseURL' in i ? i.responseURL : e.headers.get('X-Request-URL')
						var r = 'response' in i ? i.response : i.responseText
						o(new B(r, e))
					}),
						(i.onerror = function () {
							t(new TypeError('Network request failed'))
						}),
						(i.ontimeout = function () {
							t(new TypeError('Network request failed'))
						}),
						i.open(e.method, e.url, !0),
						'include' === e.credentials
							? (i.withCredentials = !0)
							: 'omit' === e.credentials && (i.withCredentials = !1),
						'responseType' in i && s && (i.responseType = 'blob'),
						e.headers.forEach(function (t, e) {
							i.setRequestHeader(e, t)
						}),
						i.send(void 0 === e._bodyInit ? null : e._bodyInit)
				})
			}),
			(t.fetch.polyfill = !0)
	}
	function d(t) {
		if (('string' != typeof t && (t = String(t)), /[^a-z0-9\-#$%&'*+.\^_`|~]/i.test(t)))
			throw new TypeError('Invalid character in header field name')
		return t.toLowerCase()
	}
	function y(t) {
		return 'string' != typeof t && (t = String(t)), t
	}
	function p(e) {
		var t = {
			next: function () {
				var t = e.shift()
				return { done: void 0 === t, value: t }
			},
		}
		return (
			r &&
				(t[Symbol.iterator] = function () {
					return t
				}),
			t
		)
	}
	function l(e) {
		;(this.map = {}),
			e instanceof l
				? e.forEach(function (t, e) {
						this.append(e, t)
					}, this)
				: Array.isArray(e)
					? e.forEach(function (t) {
							this.append(t[0], t[1])
						}, this)
					: e &&
						Object.getOwnPropertyNames(e).forEach(function (t) {
							this.append(t, e[t])
						}, this)
	}
	function c(t) {
		if (t.bodyUsed) return Promise.reject(new TypeError('Already read'))
		t.bodyUsed = !0
	}
	function b(r) {
		return new Promise(function (t, e) {
			;(r.onload = function () {
				t(r.result)
			}),
				(r.onerror = function () {
					e(r.error)
				})
		})
	}
	function m(t) {
		var e = new FileReader(),
			r = b(e)
		return e.readAsArrayBuffer(t), r
	}
	function w(t) {
		if (t.slice) return t.slice(0)
		var e = new Uint8Array(t.byteLength)
		return e.set(new Uint8Array(t)), e.buffer
	}
	function v() {
		return (
			(this.bodyUsed = !1),
			(this._initBody = function (t) {
				if ((this._bodyInit = t))
					if ('string' == typeof t) this._bodyText = t
					else if (s && Blob.prototype.isPrototypeOf(t)) this._bodyBlob = t
					else if (o && FormData.prototype.isPrototypeOf(t)) this._bodyFormData = t
					else if (e && URLSearchParams.prototype.isPrototypeOf(t)) this._bodyText = t.toString()
					else if (n && s && a(t))
						(this._bodyArrayBuffer = w(t.buffer)), (this._bodyInit = new Blob([this._bodyArrayBuffer]))
					else {
						if (!n || (!ArrayBuffer.prototype.isPrototypeOf(t) && !h(t))) throw new Error('unsupported BodyInit type')
						this._bodyArrayBuffer = w(t)
					}
				else this._bodyText = ''
				this.headers.get('content-type') ||
					('string' == typeof t
						? this.headers.set('content-type', 'text/plain;charset=UTF-8')
						: this._bodyBlob && this._bodyBlob.type
							? this.headers.set('content-type', this._bodyBlob.type)
							: e &&
								URLSearchParams.prototype.isPrototypeOf(t) &&
								this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8'))
			}),
			s &&
				((this.blob = function () {
					var t = c(this)
					if (t) return t
					if (this._bodyBlob) return Promise.resolve(this._bodyBlob)
					if (this._bodyArrayBuffer) return Promise.resolve(new Blob([this._bodyArrayBuffer]))
					if (this._bodyFormData) throw new Error('could not read FormData body as blob')
					return Promise.resolve(new Blob([this._bodyText]))
				}),
				(this.arrayBuffer = function () {
					return this._bodyArrayBuffer ? c(this) || Promise.resolve(this._bodyArrayBuffer) : this.blob().then(m)
				})),
			(this.text = function () {
				var t,
					e,
					r,
					o = c(this)
				if (o) return o
				if (this._bodyBlob) return (t = this._bodyBlob), (e = new FileReader()), (r = b(e)), e.readAsText(t), r
				if (this._bodyArrayBuffer)
					return Promise.resolve(
						(function (t) {
							for (var e = new Uint8Array(t), r = new Array(e.length), o = 0; o < e.length; o++)
								r[o] = String.fromCharCode(e[o])
							return r.join('')
						})(this._bodyArrayBuffer)
					)
				if (this._bodyFormData) throw new Error('could not read FormData body as text')
				return Promise.resolve(this._bodyText)
			}),
			o &&
				(this.formData = function () {
					return this.text().then(A)
				}),
			(this.json = function () {
				return this.text().then(JSON.parse)
			}),
			this
		)
	}
	function _(t, e) {
		var r,
			o,
			n = (e = e || {}).body
		if (t instanceof _) {
			if (t.bodyUsed) throw new TypeError('Already read')
			;(this.url = t.url),
				(this.credentials = t.credentials),
				e.headers || (this.headers = new l(t.headers)),
				(this.method = t.method),
				(this.mode = t.mode),
				n || null == t._bodyInit || ((n = t._bodyInit), (t.bodyUsed = !0))
		} else this.url = String(t)
		if (
			((this.credentials = e.credentials || this.credentials || 'omit'),
			(!e.headers && this.headers) || (this.headers = new l(e.headers)),
			(this.method = ((r = e.method || this.method || 'GET'), (o = r.toUpperCase()), -1 < u.indexOf(o) ? o : r)),
			(this.mode = e.mode || this.mode || null),
			(this.referrer = null),
			('GET' === this.method || 'HEAD' === this.method) && n)
		)
			throw new TypeError('Body not allowed for GET or HEAD requests')
		this._initBody(n)
	}
	function A(t) {
		var n = new FormData()
		return (
			t
				.trim()
				.split('&')
				.forEach(function (t) {
					if (t) {
						var e = t.split('='),
							r = e.shift().replace(/\+/g, ' '),
							o = e.join('=').replace(/\+/g, ' ')
						n.append(decodeURIComponent(r), decodeURIComponent(o))
					}
				}),
			n
		)
	}
	function B(t, e) {
		e || (e = {}),
			(this.type = 'default'),
			(this.status = void 0 === e.status ? 200 : e.status),
			(this.ok = 200 <= this.status && this.status < 300),
			(this.statusText = 'statusText' in e ? e.statusText : 'OK'),
			(this.headers = new l(e.headers)),
			(this.url = e.url || ''),
			this._initBody(t)
	}
})('undefined' != typeof window ? window : 'undefined' != typeof self ? self : this)

/**
 * Polyfill for CustomEvent
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
;(function () {
	if (typeof window.CustomEvent === 'function') return false

	function CustomEvent(event, params) {
		params = params || { bubbles: false, cancelable: false, detail: null }
		var evt = document.createEvent('CustomEvent')
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
		return evt
	}

	window.CustomEvent = CustomEvent
})()

// Scroll-to-top Button
$(document).ready(function () {
	let scrollToTopBtn = $('#scrollToTopBtn')
	let chatbotUi = $('.chatbot-ui')
	let rootElement = document.documentElement
	let executed = false
	let footerHeight = $('.site-footer').height()

	document.addEventListener('scroll', function (e) {
		if (window.innerHeight + window.scrollY + footerHeight >= document.body.offsetHeight) {
			if (!executed) {
				executed = true
				scrollToTopBtn.addClass('visible')
				chatbotUi.addClass('scroll-btn-visible')
			}
		} else {
			if (executed) {
				executed = false
				scrollToTopBtn.removeClass('visible')
				chatbotUi.removeClass('scroll-btn-visible')
			}
		}
	})
	scrollToTopBtn.click(function () {
		rootElement.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	})

	document.querySelector('#privacysettings').addEventListener('click', function (event) {
		event.preventDefault()
		UC_UI.showSecondLayer()
	})
})
