import $ from 'jquery'

const $akkordeonTab = $('.accordion-trigger')
$akkordeonTab.click(function (e) {
	e.preventDefault()
	let $akkordeonTabs = $(this).parent().parent().find('.js-tabs-panel.is-open')
	const $openPanel = $(this).closest('.is-open').parent()
	const clickedTab = $(this)

	$akkordeonTabs = $akkordeonTabs.filter((x, element) => {
		return element.id !== clickedTab.parent().attr('id')
	})
})

// Additional code for Accordion-opening, if entered by anchor-link
$(document).ready(function () {
	openAccordion()
})
window.addEventListener('hashchange', function () {
	openAccordion()
})

function openAccordion() {
	let hash = window.location.hash

	if (hash) {
		let accordiontab = $(hash)
		let accordioncontent = $(hash + ' .content')
		if (accordiontab.length && accordiontab.hasClass('tabs-panel')) {
			accordiontab.removeClass('is-hidden')
			accordiontab.addClass('is-open')

			accordioncontent.removeClass('is-hidden')
			accordioncontent.addClass('is-open')
			accordioncontent.attr({ 'aria-hidden': 'false' })
		}
	}
}
